<template>
    <div>
        <div class="userheader_wrapper">
            <div class="userdatacontent">
                <div class="userheader_content" >
                    <div class="userheaderitem" v-show="!isLogin">
                        <img src="../../assets/logo_h.png" alt="" class="useravaterimg" style=" border-radius:0%">
                        <span class="username" style="font-weight: 600">{{nousername}}</span>
                    </div>
                    <div class="userheaderitem" v-show="isLogin">
                      <img :src="userHearderImg" alt="" class="useravaterimg" style=" border-radius:0%">
                      <span class="username" style="font-weight: 600">{{userName}}</span>
                    </div>
                </div>
                <div class="background">
                    <img src="../../assets/about-us.jpg" alt="" class="" width="100%" height="100%">
                </div>
            </div>
        </div>
        <div class="ivu-row" style="background-color: white;margin-top: 10px">
          <div class="content-header" @click="functionClick(0)">
            <div class="content-header-left">
              <h1 style="margin-left: 10px;color: black;line-height:30px">我的捐款</h1>
            </div>
            <div class="content-header-right"><i class="cubeic-arrow"></i>
            </div>
          </div>
          <div class="content-header" @click="functionClick(1)">
            <div class="content-header-left">
              <h1 style="margin-left: 10px;color: black;line-height:30px">我的收藏</h1>
            </div>
            <div class="content-header-right"><i class="cubeic-arrow"></i>
            </div>
          </div>
<!--          <div class="content-header" @click="functionClick(2)">-->
<!--            <div class="content-header-left">-->
<!--              <h1 style="margin-left: 10px;color: black;line-height:30px">修改资料</h1>-->
<!--            </div>-->
<!--            <div class="content-header-right"><i class="cubeic-arrow"></i>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <cube-button :primary="true" type="submit" style="width: 90%;margin-left: 5%;margin-top: 20px"
          @click="loginClick" v-show="!isLogin">登录</cube-button>
    </div>
</template>

<script>
    // import headerderail from 'cp/v-header/headerdetail.vue'
    import { todoStorage } from 'libs/storage'
    export default {
        name: 'user',
        components: {
            // headerderail
        },
        data () {
            return {
                backshow: true,
                headertitle: 'My',
                isLogin: false,
                userHearderImg: '',
                userName: '',
                nousericon: '../../assets/logo_h.png',
                nousername: '未登录',
                title: '',
                feeds: {
                    data: [],
                    total: 0,
                    current_page: 1,
                    per_page: 10
                },
                pfeeds: {
                    data: [],
                    total: 0,
                    current_page: 1,
                    per_page: 10
                },
                searchForm: {
                    phone: ''
                },
                rSearchForm: {
                    order_by: 'id,desc',
                    osrecommend: 'T',
                    shopname: ''
                },
                selectedLabelSlots: 'My Orders',
                tabs: [
                    {
                        label: 'My Orders',
                        icon: 'cubeic-vip',
                        data: []
                    }
                ]
            }
        },
        mounted () {
          let t = this
          t.isLogin = todoStorage.getUserIsLogin
          if (t.isLogin) {
            t.userHearderImg = todoStorage.getUserData.head_image.url
            t.userName = todoStorage.getUserData.name
          }
            // t.getDataExcute(t.feeds.current_page)
        },
        methods: {
            functionClick (id) {
              let t = this
              if (todoStorage.getUserIsLogin) {
                switch (id) {
                  case 0:
                    t.$router.push({ name: 'userdonationlist', params: { userid: todoStorage.getUserData.id } })
                    break
                  default:
                    this.NoteTips('该功能尚未开发，敬请期待！')
                    break
                }
              } else {
                    t.NoteTips('您还没有登录，请先登录')
                    t.$router.push({ name: 'login' })
              }
            },
            loginClick (label) {
              this.$router.push({ name: 'login' })
            },
            getDataExcute (topage) {
                // let t = this
                // t.showLoading()
                // t.feeds.current_page = topage
              // console.log(topage)
            }
        }
    }
</script>

<style lang="stylus">
    @import "user.styl"
</style>
